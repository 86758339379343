(function() {
  'use strict';

  // header menu

  // $('.burger').on('click', function(e){
  //   e.preventDefault();

  //   $(this).toggleClass('active');
  //   $('.header-nav').slideToggle();
  // });

  // $(window).resize(function(){
	// 	var menu = $(".header-nav")
	// 	var wid = $(window).width();
	// 	if(wid > 568 && menu.is(":hidden")){
	// 		menu.removeAttr('style');
	// 	}
  // });
  
  // next btn

  // $('.next-btn').click(function(event) {
  //   var currentSlideHeight = $(this).parent().next().offset().top;

  //   console.log($('html, body').scrollTop(), currentSlideHeight)
  
	//   $('html, body').animate({
	// 	    scrollTop : currentSlideHeight,
	//   }, 1000);
	//   //Prevents the # from the link appearing in the url.
	//   event.preventDefault();
  // });

  // if($('#scene').length !== 0){
  //   var scene = document.getElementById('scene');
  //   var parallaxInstance = new Parallax(scene);
  // }
  
  // poppup

  $('.popup-frame').magnificPopup({
		disableOn: 700,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

	$('.popup-img').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		mainClass: 'mfp-img-mobile',
		image: {
			verticalFit: true
		}
		
  });

  $('.popup').magnificPopup({
		type: 'inline',
    preloader: false,
    closeOnContentClick: false,
  });

  //

  svg4everybody();
  

  //table
  
  if($('table.responsive').length > 0){
    $('table.responsive').ngResponsiveTables();
  }
	
  //select styler

  $('select').styler();

  //datepicker

  // $( ".datepicker" ).datepicker();

  // $("#weeklyDatePicker").datetimepicker({
  //   format: 'DD-MM-YYYY'
  // });

  // //Get the value of Start and End of Week
  // $('#weeklyDatePicker').on('dp.change', function (e) {
  //     var value = $("#weeklyDatePicker").val();
  //     var firstDate = moment(value, "DD-MM-YYYY").day(0).format("DD-MM-YYYY");
  //     var lastDate =  moment(value, "DD-MM-YYYY").day(6).format("DD-MM-YYYY");
  //     $("#weeklyDatePicker").val(firstDate + " - " + lastDate);
  // });
   
  //tabs

  $('.tabs__wrap').each(function() {
    $(this).find('.tab').each(function(i) {
      $(this).parents('.tabs__wrap').find('.tab_content').children().not(':first').hide();
      $(this).click(function(){
        $(this).addClass('active').siblings().removeClass('active')
        $(this).parents('.tabs__wrap').find('.tab_content').children().eq(i).fadeIn(0).siblings('.tab_item').hide();
      
        if($('.stat-slider_withdraw').length !== 0){
          statDepositSlider.update();
          statWithdrawSlider.update();
        }
        if($('.invest-slider').length !== 0){
          standartSlider.update();
          advancedSlider.update();
          premiumSlider.update();
        }
      });
    });
  });

  //tabs
  $('[data-tabs-btn]').click(function() {
    let tabsName = $(this).parent().attr('data-tabs-btns');
    let tabNo = $(this).attr('data-tabs-btn');
    let tabsWrapper = $('[data-tabs-wrapper='+tabsName+']');

    $(this).siblings().removeClass('active');
    $(this).addClass('active');

    console.log(tabsName)
    tabsWrapper.children().each(function(i, item) {
      $(item).hide();
      if ($(item).attr('data-tabs-item') === tabNo) {
        $(item).show();
      }
    });
  });

  function tabsInitialization() {
    let btns = $('[data-tabs-btns]');
    let wrapper = $('[data-tabs-wrapper]');

    $(wrapper).children().not(function() {
      if ($(this).attr('data-tabs-item') === '1') {
        return true;
      }
    }).hide();

    $(btns).children().not(function() {
      if ($(this).attr('data-tabs-btn') === '1') {
        return false;
      } else {
        return true;
      }
    }).addClass('active');
  }
  
  tabsInitialization();

  //accordion

  var Accordion = function(el, multiple) {
    this.el = el || {};
    this.multiple = multiple || false;

    // Variables privadas
    var links = this.el.find('.accordion__head');
    // Evento
    links.on('click', {el: this.el, multiple: this.multiple}, this.dropdown)
  }

  Accordion.prototype.dropdown = function(e) {
    var $el = e.data.el,
        $this = $(this),
        $next = $this.next();

    $next.slideToggle();
    $this.parent().toggleClass('active');

    if (!e.data.multiple) {
      $el.find('.accordion__body').not($next).slideUp().parent().removeClass('active');
    };
  }	

  var accordion = new Accordion($('.accordion'), false);
  
  //nicescroll

  $(".nicescroll-box").niceScroll(".wrap",{
    cursorcolor:"#7455c5",
    cursorwidth:"3px",
    zindex: 20,
    emulatetouch: true,
    autohidemode: false,
  });

  $( "#amount" ).spinner({
    step: 0.01,
    numberFormat: "n"
  });

  $( "#spinner" ).spinner();
  
  // aos

  AOS.init(
    {
      // Global settings
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
      offset: 0, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 700, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    }
  );

	setTimeout(AOS.refreshHard, 1000);
	
	//clipboard

  var affil1 = new Clipboard('#affil');
  var banners = new Clipboard('.cabinet-banner__copy');

  function affiliatelink(id) {
    id.on('success', function (e) {
      // swal({
      //   title: "Your referral link copied!",
      //   text: "You can paste text that has been copied by pressing Ctrl + V. The text that was copied last will be pasted.",
      //   type: "success",
      //   showCancelButton: false,
      //   confirmButtonClass: "btn-success",
      //   confirmButtonText: "OK!",
      //   closeOnConfirm: false,
      //   closeOnCancel: false
      // });

      Lobibox.notify('success', {
        title: true,
        size: 'normal',
        icon: true,
        sound: false,
        iconSource: "bootstrap",
        msg: 'Your referral link copied!'
      });
    });
  }

  affiliatelink(affil1);
  affiliatelink(banners);

  // sliders

  var statDepositSlider = new Swiper('.stat-slider_deposit .swiper-container', {
    loop: true,
    slidesPerView: 4,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.stat-slider_deposit .swiper-button-next',
      prevEl: '.stat-slider_deposit .swiper-button-prev',
    },

    breakpoints: {
      1200: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      560: {
        slidesPerView: 1,
      },
    }
  });

  var statWithdrawSlider = new Swiper('.stat-slider_withdraw .swiper-container', {
    loop: true,
    slidesPerView: 4,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.stat-slider_withdraw .swiper-button-next',
      prevEl: '.stat-slider_withdraw .swiper-button-prev',
    },

    breakpoints: {
      1200: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 2,
      },
      560: {
        slidesPerView: 1,
      },
    }
  });

  var standartSlider = new Swiper('#standart .swiper-container', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 0,
    direction: 'vertical',
    slideToClickedSlide: true,
    navigation: {
      nextEl: '#standart .swiper-button-next',
      prevEl: '#standart .swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    }
  });

  var advancedSlider = new Swiper('#advanced .swiper-container', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 0,
    direction: 'vertical',
    slideToClickedSlide: true,
    navigation: {
      nextEl: '#advanced .swiper-button-next',
      prevEl: '#advanced .swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
  });

  var premiumSlider = new Swiper('#premium .swiper-container', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 0,
    direction: 'vertical',
    slideToClickedSlide: true,
    navigation: {
      nextEl: '#premium .swiper-button-next',
      prevEl: '#premium .swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
  });

  var walletsSlider = new Swiper('.wallets-slider .swiper-container', {
    loop: true,
    slidesPerView: 5,
    spaceBetween: 10,
    navigation: {
      nextEl: '.wallets-slider .swiper-button-next',
      prevEl: '.wallets-slider .swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },

    breakpoints: {
      1200: {
        slidesPerView: 4,
      },
    }
  });

  var certSlider = new Swiper('.cert-slider .swiper-container', {
    loop: true,
    spaceBetween: 70,
    slidesPerView: 5,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },

    breakpoints: {
      1860: {
        slidesPerView: 4,
      },
      1500: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      560: {
        slidesPerView: 2,
      },
      480: {
        slidesPerView: 1,
      },
    }
  });

  var roadmapSlider = new Swiper('.roadmap-slider .swiper-container', {
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: '.roadmap-slider .swiper-button-next',
      prevEl: '.roadmap-slider .swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.roadmap-slider .swiper-pagination',
      type: 'bullets',
      clickable: true,
      renderBullet: function (index, className) {
        if((index + 1) < 10){
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        }else{
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
        
      },
    },
  });

  if($('.roadmap-nav').length !== 0){
    var roadmapItem = $('.roadmap-nav__item');
  
    roadmapItem.on('click', function(){
      $(this).addClass('active');
      $(this).siblings().removeClass('active');
      roadmapSlider.slideTo($(this).index() + 1)
    })
  
    roadmapSlider.on('slideChange', function(){
      roadmapItem.eq(roadmapSlider.realIndex).addClass('active');
      roadmapItem.eq(roadmapSlider.realIndex).siblings().removeClass('active');
    })
  }

  var cabBalance = new Swiper('.cabinet-balance__wallets .swiper-container', {
    loop: true,
    slidesPerView: 4,
    spaceBetween: 8,
    direction: 'vertical',
    slideToClickedSlide: true,
    navigation: {
      nextEl: '.cabinet-balance__nav .swiper-button-next',
      prevEl: '.cabinet-balance__nav .swiper-button-prev',
    },
  });

  var cabBalanceContent = new Swiper('.cabinet-balance__content .swiper-container', {
    loop: true,
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  });

  cabBalance.on('slideChange', function(){
    cabBalanceContent.slideTo(cabBalance.realIndex + 1);
  })


  setTimeout(() => {
    $('.marquee').marquee({
      //speed in milliseconds of the marquee
      duration: 13000,
      //gap in pixels between the tickers
      gap: 0,
      //time in milliseconds before the marquee will start animating
      delayBeforeStart: 0,
      //'left' or 'right'
      direction: 'left',
      //true or false - should the marquee be duplicated to show an effect of continues flow
      duplicated: true
    });
  }, 1000);

  //cabinet-settings

  // $('.cabinet-table__settings-btn').on('click', function(e){
  //   e.preventDefault();
  
    
  
  //   $(this).siblings('.cabinet-table__settings-hide').fadeIn(300);
  //   $(this).parent().addClass('active');
  //   let trueH = ($(document).outerHeight(true) - $(this).siblings('.cabinet-table__settings-hide').offset().top - $(this).siblings('.cabinet-table__settings-hide').outerHeight(true));
  
  //   if(trueH <= 0){
  //     $(this).siblings('.cabinet-table__settings-hide').addClass('top');
  //   }
  // });
  
  // $(document).mouseup(function (e){ 
  //   var block = $(".cabinet-table__settings.active .cabinet-table__settings-hide"); 
  //   if (!block.is(e.target) 
  //       && block.has(e.target).length === 0) { 
  //       block.hide(); 
  
  //       block.parent().removeClass('active');
  
  //       if( block.hasClass('top')){
  //         block.removeClass('top');
  //       }
  //   }
  // });



  // //chart profit

  // if($("#trade").length !== 0){
  //   var ctx = document.getElementById('trade').getContext('2d');
  //   var gradientFill = ctx.createLinearGradient(0, 0, 0, 300);
  //   gradientFill.addColorStop(0, "rgba(100, 221, 249, 1)");
  //   gradientFill.addColorStop(1, "rgba(100, 221, 249, 0)");
  //   var chart = new Chart(ctx, {
  //     // The type of chart we want to create
  //     type: 'line',
  //     data: {
  //       labels: ['Mon', 'Tues', 'Wed', 'Thurs','Fri','Sat','Sun'],
  //       datasets: [{
  //         data: [{
  //           x: 'Mon',
  //           y: 250
  //         }, {
  //           x: 'Tues',
  //           y: 105
  //         },
  //         {
  //           x: 'Wed',
  //           y: 90
  //         },
  //         {
  //           x: 'Thurs',
  //           y: 175
  //         },
  //         {
  //           x: 'Fri',
  //           y: 125
  //         },
  //         {
  //           x: 'Sat',
  //           y: 145
  //         },
  //         {
  //           x: 'Sun',
  //           y: 275
  //         }],
  //         backgroundColor: gradientFill,
  //         borderColor: '#64ddf9',
  //         borderWidth: 4,
  //       }]
  //     },

      
  
  //     // Configuration options go here
  //     options: {
  //       cutoutPercentage: 70,
  //       responsive: true,
  //       maintainAspectRatio: false,
  //       legend: {
  //         display: false
  //       },
  //       scales: {
  //         yAxes: [{
  //             ticks: {
  //                 fontColor: "white",
  //             }
  //         }],
  //         xAxes: [{
  //             ticks: {
  //                 fontColor: "white",
  //             }
  //         }]
  //     }
        
  //     }
  //   });
  // }
 

})();